// 时间戳转换成日期格式
export const dateFormat = (datetime, format) => {
  var date = {
    'M+': datetime.getMonth() + 1,
    'd+': datetime.getDate(),
    'h+': datetime.getHours(),
    'm+': datetime.getMinutes(),
    's+': datetime.getSeconds(),
    'q+': Math.floor((datetime.getMonth() + 3) / 3),
    'S+': datetime.getMilliseconds()
  }
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (datetime.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (var k in date) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? date[k]
          : ('00' + date[k]).substr(('' + date[k]).length)
      )
    }
  }
  return format
}

export const getTime = (time) => {
  var date = new Date(time)
  var y = date.getFullYear()
  var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  var d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  return y + '-' + m + '-' + d
}


export const getFormatTime1 = (time1, todays) => {
  // 天
  let staytimeGap = new Date(time1).getTime() - new Date(todays).getTime()
  let stayDay = Math.floor(staytimeGap / (3600 * 1000 * 24));
  // 时
  let leave = staytimeGap % (3600 * 1000 * 24);
  // let stayHour = formAdd(Math.floor(leave / (3600 * 1000)))
  let stayHour = Math.floor(leave / (3600 * 1000))
  // 分
  let leave1 = leave % (3600 * 1000);
  let stayMin = Math.floor(leave1 / (60 * 1000))
  // 秒
  let leave2 = leave1 % (60 * 1000);
  let staySec = Math.floor(leave2 / 1000)

  return { stayDay: stayDay, stayHour: stayHour, stayMin: stayMin, staySec: staySec }

}
