import axios from '@/libs/api.request'

export const OrganiztionfindPage = (pageNum, pageSize) => {
  let uri = '/organization/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const addOrganiztion = entity => {
  let uri = '/organization/'
  return axios.request({
    url: uri,
    method: 'put',
    data: entity
  })
}

export const OrganizationfindNamePage = (name, pageNum, pageSize) => {
  let uri = '/organization/name/' + name + '/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getOrganizationById = id => {
  let uri = '/organization/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const updateOrganization = (entity, id) => {
  let uri = '/organization/' + id
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

export const deleteOrganization = id => {
  let uri = '/organization/' + id
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const countOrganizationByName = name => {
  let uri = '/organization/count/name/' + name
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const batchDeleteUser = (organizationId, userIds) => {
  let uri = '/organization/' + organizationId + '/user'
  return axios.request({
    url: uri,
    method: 'post',
    data: userIds
  })
}

export const batchInsertUser = (organizationId, userIds) => {
  let uri = '/organization/' + organizationId + '/user'
  return axios.request({
    url: uri,
    method: 'put',
    data: userIds
  })
}

export const deleteAllUser = organizationId => {
  let uri = '/organization/' + organizationId + '/user'
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const InsertUser = (organizationId, userId) => {
  let uri = '/organization/' + organizationId + '/user/' + userId
  return axios.request({
    url: uri,
    method: 'put',
    data: userId
  })
}

export const deleteUser = (organizationId, userId) => {
  let uri = '/organization/' + organizationId + '/user/' + userId
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const getOrganizationUsers = organizationId => {
  let uri = '/organization/users/' + organizationId
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getOrganizations = () => {
  let uri = '/organization/'
  return axios.request({
    url: uri,
    method: 'get'
  })
}
