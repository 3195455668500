import axios from '@/libs/api.request'

export const getRoleALL = () => {
  let uri = '/role/getRoleAll'
  return axios.request({
    url: uri,
    method: 'get'
  })
}


export const RolefindPage = (pageNum, pageSize) => {
  let uri = '/role/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const addRole = entity => {
  let uri = '/role/'
  return axios.request({
    url: uri,
    method: 'put',
    data: entity
  })
}

export const RolefindNamePage = (name, pageNum, pageSize) => {
  let uri = '/role/name/' + name + '/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getRoleById = id => {
  let uri = '/role/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const updateRole = (entity, id) => {
  let uri = '/role/' + id
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

export const deleteRole = id => {
  let uri = '/role/' + id
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const batchDeletePermission = (permissionIds, roleId) => {
  let uri = '/role/' + roleId + '/permission'
  return axios.request({
    url: uri,
    method: 'post',
    data: permissionIds
  })
}

export const batchInsertPermission = (permissionIds, roleId) => {
  let uri = '/role/' + roleId + '/permission'
  return axios.request({
    url: uri,
    method: 'put',
    data: permissionIds
  })
}

export const InsertPermission = (permissionId, roleId) => {
  let uri = '/role/' + roleId + '/permission/' + permissionId
  return axios.request({
    url: uri,
    method: 'put',
    data: permissionId
  })
}

export const deleteAllPermission = roleId => {
  let uri = '/role/' + roleId + '/permission'
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const deletePermission = (permissionId, roleId) => {
  let uri = '/role/' + roleId + '/permission/' + permissionId
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const batchDeleteUser = (roleId, userIds) => {
  let uri = '/role/' + roleId + '/user'
  return axios.request({
    url: uri,
    method: 'post',
    data: userIds
  })
}

export const batchInsertUser = (roleId, userIds) => {
  let uri = '/role/' + roleId + '/user'
  return axios.request({
    url: uri,
    method: 'put',
    data: userIds
  })
}

export const InsertUser = (roleId, userId) => {
  let uri = '/role/' + roleId + '/user/' + userId
  return axios.request({
    url: uri,
    method: 'put',
    data: userId
  })
}

export const deleteAllUser = roleId => {
  let uri = '/role/' + roleId + '/user'
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const deleteUser = (roleId, userId) => {
  let uri = '/role/' + roleId + '/user/' + userId
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const countByName = name => {
  let uri = '/role/count/name/' + name
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getRoleUsers = roleId => {
  let uri = '/role/users/' + roleId
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getRolePermissions = roleId => {
  let uri = '/role/' + roleId + '/permission'
  return axios.request({
    url: uri,
    method: 'get'
  })
}
