import axios from '@/libs/api.request'

export const loginUser = entity => {
  let uri = '/user/auth'
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

export const logoutUser = (id) => {
  let uri = '/user/auth'
  return axios.request({
    url: uri,
    method: 'get',
    params: {
      id: id
    }
  })
}

export const changepwd = entity => {
  let uri = '/user/pwd'
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

export const UserfindPage = (pageNum, pageSize) => {
  let uri = '/user/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const addUser = entity => {
  let uri = '/user/'
  return axios.request({
    url: uri,
    method: 'put',
    data: entity
  })
}

export const getUsers = () => {
  let uri = '/user/'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 不确定
export const addRole = (roleId, userId) => {
  let uri = '/user/' + userId + '/role/' + roleId
  return axios.request({
    url: uri,
    method: 'put',
    data: roleId
  })
}

export const deleterole = (roleId, userId) => {
  let uri = '/user/' + userId + '/role/' + roleId
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const updateUser = (entity, id) => {
  let uri = '/user/' + id
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

// export const deleteUser = id => {
//   let uri = '/user/' + id
//   return axios.request({
//     url: uri,
//     method: 'delete'
//   })
// }

export const UserStatus = (id, status) => {
  let uri = '/user/' + id + '/' + status
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getUserById = id => {
  let uri = '/user/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const UserfindNamePage = (name, pageNum, pageSize) => {
  let uri = '/user/name/' + name + '/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const BatchaddRoles = (roleIds, userId) => {
  let uri = '/user/' + userId + '/user'
  return axios.request({
    url: uri,
    method: 'put',
    data: roleIds
  })
}

export const BatchdeleteRoles = (roleIds, userId) => {
  let uri = '/user/' + userId + '/role'
  return axios.request({
    url: uri,
    method: 'post',
    data: roleIds
  })
}

export const deleteAllrole = userId => {
  let uri = '/user/' + userId + '/role'
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const getUserRoles = userId => {
  let uri = '/user/' + userId + '/role'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getUserPermissions = userId => {
  let uri = '/user/' + userId + '/permission'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getUserOrganizations = userId => {
  let uri = '/user/organizations/' + userId
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const UsercountByName = name => {
  let uri = '/user/count/name/' + name
  return axios.request({
    url: uri,
    method: 'get'
  })
}



// 用户管理接口
export const createUser = (password, username, url) => {
  let uri = '/user/createUser'
  return axios.request({
    url: uri,
    method: 'post',
    params: {
      password: password,
      username: username,
      url: url
    }
  })
}

export const getAllUser = (pageNum, pageSize, quiese) => {
  let uri = '/user/getAllUser/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const FrozenUser = (userId, type) => {
  let uri = '/user/FrozenUser/' + userId + '/' + type
  return axios.request({
    url: uri,
    method: 'get'
  })
}


// 添加官方账号
export const addOfficialUser = (userId) => {
  let uri = '/user/addOfficialUser/' + userId
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 查询官方账号
export const getAllOfficialUser = () => {
  let uri = '/user/getAllOfficialUser'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 赠送vip
export const giveVip = (quiese) => {
  let uri = '/user/giveVip'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

// 移除官方账号
export const removeOfficialUser = (id) => {
  let uri = '/user/removeOfficialUser/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}



