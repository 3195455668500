<template>
  <div>
    <!-- breadcrumb面包屑导航区域 -->
    <!-- 首页/活动管理/活动列表/活动详情 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form :model="queryinfo" ref="queryinfoRef">
          <el-col :span="6.5">
            <el-form-item prop="queryname">
              <div style="display: inline-block" class="divSpan">姓名：</div>
              <div style="display: inline-block">
                <ELselect
                  v-model="queryinfo.queryname"
                  :options="Names"
                  :optionKey="Nameskeys"
                  Splaceholder="请选择搜索的姓名"
                  @clear="getUserList"
                ></ELselect>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <!-- <el-divider></el-divider> -->
      <el-row :gutter="30">
        <el-col :span="3">
          <el-button
            round
            type="primary"
            icon="el-icon-circle-plus"
            @click="addDialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="UserList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            sortable
            min-width="120px"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            sortable
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="邮箱"
            sortable
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            sortable
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
            min-width="100px"
          ></el-table-column>

          <!-- status状态渲染 -->
          <el-table-column label="状态" min-width="80px">
            <template slot-scope="scope">
              <el-switch
                active-value="1"
                inactive-value="0"
                v-model="scope.row.status"
                @change="userStatusChanged(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>

          <el-table-column label="角色" min-width="250px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewroleEditDialog(scope.row)"
                >查看</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="addroleDialog(scope.row)"
                >分配</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="removeroleDialog(scope.row)"
                >移除</el-button
              >

              <!-- <el-button
                type="primary"
                icon="el-icon-view"
                size="mini"
                @click="viewroleEditDialog(scope.row)"
              ></el-button>
              <el-tooltip
                effect="dark"
                content="分配用户角色"
                placement="top"
                :enterable="false"
              >
                <el-button
                  type="primary"
                  icon="el-icon-circle-plus"
                  size="mini"
                  @click="addroleDialog(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="移除用户角色"
                placement="top"
                :enterable="false"
              >
                <el-button
                  type="primary"
                  icon="el-icon-remove"
                  size="mini"
                  @click="removeroleDialog(scope.row)"
                ></el-button>
              </el-tooltip> -->
            </template>
          </el-table-column>

          <el-table-column label="组织" min-width="100px">
            <template slot-scope="scope">
              <!-- 查看按钮 -->
              <el-button
                type="primary"
                size="mini"
                @click="viewOrganizeEditDialog(scope.row)"
                >查看组织</el-button
              >
            </template>
          </el-table-column>

          <!-- operation 渲染 -->
          <el-table-column label="操作" min-width="150px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewEditDialog(scope.row)"
                >查看</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="showEditDialog(scope.row)"
                >编辑</el-button
              >
              <!-- 删除按钮 -->
              <!--<el-button type="danger" icon="el-icon-delete" size="mini" @click="removeUserById(scope.row)"></el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="查看用户信息"
      :visible.sync="viewDialogVisible"
      width="30%"
      @close="viewDialogClosed"
    >
      <el-form :model="viewForm" ref="viewFormRef" label-width="100px">
        <el-form-item label="ID：">
          <el-input v-model.trim="viewForm.id"></el-input>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input v-model.trim="viewForm.name"></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input v-model.trim="viewForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model.trim="viewForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="用户名：">
          <el-input v-model.trim="viewForm.username"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-input v-model.trim="viewForm.status"></el-input>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-input v-model.trim="viewForm.createTime"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="编辑用户信息"
      :visible.sync="editDialogVisible"
      width="30%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input v-model.trim="editForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model.trim="editForm.email" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model.trim="editForm.phone" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editUserInfo">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="添加用户信息"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域  表单-->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input v-model.trim="addForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model.trim="addForm.email" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model.trim="addForm.phone" clearable></el-input>
        </el-form-item>
        <el-form-item label="用户名：" prop="username">
          <el-input v-model.trim="addForm.username" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input
            show-password
            v-model.trim="addForm.password"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addUSer">确定</el-button>
      </span>
    </el-dialog>

    <!-- 查看role-->
    <el-dialog
      :close-on-click-modal="false"
      title="查看角色权限"
      :visible.sync="viewroleDialogVisible"
      width="50%"
    >
      <el-row :gutter="30" style="display: flex">
        <el-table :data="UserRoleviewList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="角色ID" sortable></el-table-column>
          <el-table-column prop="name" label="名称" sortable></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewPrivilegeEditDialog(scope.row)"
                >查看权限</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewroleDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="查看权限"
      :visible.sync="viewrolePrivilegeDialogVisible"
      width="50%"
    >
      <el-row :gutter="30" style="display: flex">
        <el-table :data="UserRolePrivilegeviewList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="ID" prop="id" sortable></el-table-column>
          <el-table-column label="说明" prop="note"></el-table-column>
          <el-table-column label="标识" prop="tag" sortable></el-table-column>
          <el-table-column label="type" prop="type" sortable></el-table-column>
          <el-table-column
            prop="resourceId"
            label="资源ID"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="viewrolePrivilegeDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!--分配用户角色-->
    <el-dialog
      :close-on-click-modal="false"
      :title="ADDUserRole"
      :visible.sync="addRoleDialogVisible"
      width="55%"
    >
      <el-row :gutter="30">
        <!-- 搜索与添加区域 加入搜索表单重置按钮-->
        <el-col :span="8">
          <div style="display: inline-block">名称：</div>
          <div style="display: inline-block">
            <el-input
              placeholder="请输入搜索的名称"
              v-model="Rolequeryinfo.queryroleName"
              clearable
            ></el-input>
          </div>
        </el-col>
        <el-col :span="2.5">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getroleAvailList"
            >搜索</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>

      <i class="texts">可用的角色列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="SelectedRole"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <!-- 列表区域  勾选框-->
        <el-table :data="roleAvailList" border stripe>
          <el-table-column width="50px">
            <template slot-scope="scope">
              <div v-if="typeof scope.row.RoleExisted == 'undefined'">
                <el-checkbox
                  @change="CheckChange(scope.row)"
                  v-model="scope.row.checkchange"
                ></el-checkbox>
              </div>
              <div v-else>
                <el-checkbox v-model="checked" disabled></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="角色ID" sortable></el-table-column>
          <el-table-column prop="name" label="名称" sortable></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleRoleSizeChange"
        @current-change="handleRoleCurrentChange"
        :current-page="Rolequeryinfo.pageNum"
        :page-size="Rolequeryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="Roletotal"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addRoleDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addRoleToUser">确定</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">已添加的角色列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="roleaddedList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="角色ID"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeRoleById(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!-- 移除用户角色 按钮-->
    <el-dialog
      :close-on-click-modal="false"
      :title="RemoveUserRole"
      :visible.sync="removeRoleDialogVisible"
      width="55%"
    >
      <i class="texts">已存在的角色列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="RemovedRole"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="UserRoleremoveList"
          border
          stripe
          @selection-change="handleRemovedChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="角色ID" sortable></el-table-column>
          <el-table-column prop="name" label="名称" sortable></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
        </el-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="removeRoleDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="removeRoleToUser">移除</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">选择要移除的角色列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="confirmRemovedRole" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="角色ID"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="NotremoveRoleById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="查看组织信息"
      :visible.sync="viewOrganizeDialogVisible"
      width="50%"
    >
      <el-row :gutter="30" style="display: flex">
        <el-table :data="OrganizeviewList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="name" label="名称" sortable></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
        </el-table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewOrganizeDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getUserOrganizations, UserfindPage, UserfindNamePage, getUserById, UserStatus, updateUser, addUser, getUserRoles, BatchaddRoles, BatchdeleteRoles, UsercountByName, getUsers } from '@/api/systemanager/user'
import { dateFormat } from '@/components/myself/dateTime'
import { getRoleById, getRolePermissions, RolefindPage, RolefindNamePage } from '@/api/systemanager/role'
import { getPrivilegeById } from '@/api/systemanager/privilege'
import { getOrganizationById } from '@/api/systemanager/organize'
import ELselect from '@/components/myself/el_select'
import { dynamicColumnId } from '@/components/myself/dynamicCol'
import { deleteDialog } from '@/libs/confirmDialog'

export default {
  components: { ELselect },
  data () {
    var Phones = (rule, value, callback) => {
      if (value.length != 11) {
        callback(new Error('长度必须为11字符'));
      } else if (/^1[3|4|5|6|7|8][0-9]\d{8}$/.test(value) === false) {
        callback(new Error('请输入有效的手机号'))
      } else {
        callback()
      }
    }
    var Emails = (rule, value, callback) => {
      if (value.length > 50) {
        callback(new Error('长度必须在1-50字符间'));
      } else if (/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value) === false) {
        callback(new Error('请输入有效的邮箱'))
      } else {
        callback()
      }
    }
    var Pwd = (rule, value, callback) => {
      if (value.length > 15) {
        callback(new Error('长度必须在1-15字符间'));
      } else if (/^[a-zA-Z0-9]+$/.test(value) === false) {
        callback(new Error('密码必须为数字或数字+英文'))
      } else {
        callback()
      }
    }
    return {
      checked1: true,
      checked: true,
      queryinfo: {
        queryname: '',
        pageNum: 1,
        pageSize: 8
      },
      Names: [],
      Nameskeys: {
        value: 'name',
        label: 'name',
        label2: ''
      },
      total: 0,
      UserList: [
      ],
      viewDialogVisible: false,
      viewForm: {},
      editDialogVisible: false,
      editForm: {
        name: '',
        phone: '',
        email: ''
      },
      editFormRules: {
        name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' },
          { min: 1, max: 10, message: '长度必须在1-10个字符间', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: Phones, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: Emails, trigger: 'blur' }
        ]
      },
      addDialogVisible: false,
      addForm: {
        name: '',
        phone: '',
        email: '',
        username: '',
        password: ''
      },
      addFormRules: {
        name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' },
          { min: 1, max: 20, message: '长度必须在1-20个字符间', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: Phones, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: Emails, trigger: 'blur' }
        ],
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { min: 1, max: 20, message: '长度必须在1-20字符间', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: Pwd, trigger: 'blur' }

        ]
      },
      // view role
      UserRoleviewList: [],
      viewroleDialogVisible: false,
      UserRolePrivilegeviewList: [],
      viewrolePrivilegeDialogVisible: false,
      // add role
      ADDUserRole: '',
      addRoleDialogVisible: false,
      Rolequeryinfo: {
        queryroleName: '',
        pageNum: 1,
        pageSize: 5
      },
      Roletotal: 0,
      roleAvailList: [],
      UserRoleIds: [],
      UserId: '',
      multipleSelection: [],
      roleaddedList: [],
      // remove role
      RemoveUserRole: '',
      removeRoleDialogVisible: false,
      multipleRemove: [],
      UserRoleremoveList: [],
      confirmRemovedRole: [],

      // organize
      OrganizeviewList: [],
      viewOrganizeDialogVisible: false,
    }
  },
  filters: {
    dateFormat (timestamp) {
      var date = new Date(timestamp)
      return dateFormat(date, 'yyyy-MM-dd hh:mm:ss')
    }
  },
  mounted () {
    this.getALLName()
    this.getUserList()
  },
  methods: {
    async getALLName () {
      const res = await getUsers()
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      // this.Names = dynamicColumnName(res.data.data)
      this.Names = res.data.data
    },
    async getUserList () {
      const res = await UserfindPage(this.queryinfo.pageNum, this.queryinfo.pageSize)
      if (res.status !== 200) { return this.$message.error('获取列表失败') }
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.UserList = res.data.data.list
      this.total = res.data.data.total

      if (this.queryinfo.queryname !== '') {
        const res = await UserfindNamePage(this.queryinfo.queryname, this.queryinfo.pageNum, this.queryinfo.pageSize)
        if (res.status !== 200) return this.$message.error('获取列表失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)

        this.UserList = res.data.data.list
        this.total = res.data.data.total
      }
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getUserList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getUserList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getUserList()
    },
    async viewEditDialog (rowinfo) {
      const res = await getUserById(rowinfo.id)
      if (res.status !== 200) return this.$message.error('查看失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.viewForm = res.data.data
      // if (typeof this.viewForm.createTime !== 'undefined') {
      //   this.viewForm.createTime = this.$options.filters.dateFormat(Number(this.viewForm.createTime))
      // }
      this.viewDialogVisible = true
    },
    viewDialogClosed () {
      this.$refs.viewFormRef.resetFields()
    },
    async userStatusChanged (rowinfo) {
      const res = await UserStatus(rowinfo.id, rowinfo.status)
      if (res.status !== 200) return this.$message.error('更新状态失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      if (rowinfo.status === '1') return this.$message.success('启用成功')
      this.$message.info('关闭成功')
    },
    async showEditDialog (rowinfo) {
      // 先获取原有数据，再进行修改刷新
      const res = await getUserById(rowinfo.id)
      if (res.status !== 200) return this.$message.error('修改失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.editForm = res.data.data
      this.editDialogVisible = true
    },
    // 监听修改对话框的关闭事件
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 修改信息并提交
    editUserInfo () {
      this.$refs.editFormRef.validate(async valid => {
        // 可以发现修改信息的请求
        if (valid) {
          const resl = await UsercountByName(this.editForm.name)
          if (resl.data.data > 0) return this.$message.error('姓名已存在')

          const res = await updateUser(this.editForm, this.editForm.id)
          if (res.status !== 200) return this.$message.error('失败的更新用户信息')
          this.editDialogVisible = false
          this.getUserList()
          this.getALLName()
          this.$message.success('成功的更新用户信息')
        }
      })
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    async addUSer () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const resll = await UsercountByName(this.addForm.name)
          if (resll.data.data > 0) return this.$message.error('姓名已存在')

          const resl = await getUsers()
          let ifexist = 0
          resl.data.data.forEach(item => {
            if (item.username === this.addForm.username) {
              ifexist = ifexist + 1
              return ifexist
            }
          })
          if (ifexist === 1) return this.$message.error('用户名已存在')

          const res = await addUser(this.addForm)
          if (res.status !== 200) return this.$message.error('失败添加用户信息')
          this.$message.success('成功添加用户信息')
          this.addDialogVisible = false
          this.getUserList()
        }
      })
    },
    // view role
    async viewroleEditDialog (rowinfo) {
      this.UserRoleviewList = []
      const res = await getUserRoles(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      // 此处是roleid，所以需去role表中再查找
      res.data.data.forEach(async item => {
        const resl = await getRoleById(item.roleId)
        if (typeof resl.data.data !== 'undefined') {
          this.UserRoleviewList.push(resl.data.data)
        }
      })
      this.viewroleDialogVisible = true
    },
    async viewPrivilegeEditDialog (rowinfo) {
      this.UserRolePrivilegeviewList = []
      const res = await getRolePermissions(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      // 此处是role的permissionId，所以需去role表中再查找
      res.data.data.forEach(async item => {
        const resl = await getPrivilegeById(item.permissionId)
        if (typeof resl.data.data !== 'undefined') {
          this.UserRolePrivilegeviewList.push(resl.data.data)
        }
      })
      this.viewrolePrivilegeDialogVisible = true
    },
    // 分配角色
    async getroleAvailList () {
      const res = await RolefindPage(this.Rolequeryinfo.pageNum, this.Rolequeryinfo.pageSize)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      // 此处是roleid，需去除此id列表再展示, object类型
      let flag = 0
      res.data.data.list.forEach(item => {
        if (this.UserRoleIds.toString().includes(item.id)) {
          flag = flag + 1
          this.$set(item, 'RoleExisted', true)
        }
        if (flag === 0) {
          this.$set(item, 'checkchange', false)
        }
      })
      this.roleAvailList = res.data.data.list
      this.Roletotal = res.data.data.total

      if (this.Rolequeryinfo.queryroleName !== '') {
        const resl = await RolefindNamePage(this.Rolequeryinfo.queryroleName, this.Rolequeryinfo.pageNum, this.Rolequeryinfo.pageSize)
        if (resl.status !== 200) return this.$message.error('获取列表失败')
        if (resl.data.status !== 200) return this.$message.error(resl.data.message)
        let flag = 0
        resl.data.data.list.forEach(item => {
          if (this.UserRoleIds.toString().includes(item.id)) {
            flag = flag + 1
            this.$set(item, 'RoleExisted', true)
          }
          if (flag === 0) {
            this.$set(item, 'checkchange', false)
          }
        })
        this.roleAvailList = resl.data.data.list
        this.Roletotal = resl.data.data.total
      }
    },
    handleRoleSizeChange (newSize) {
      this.Rolequeryinfo.pageSize = newSize
      this.getroleAvailList()
    },
    handleRoleCurrentChange (newPage) {
      this.Rolequeryinfo.pageNum = newPage
      this.getroleAvailList()
    },
    async addroleDialog (rowinfo) {
      this.ADDUserRole = '分配用户' + rowinfo.username + '角色'
      this.UserId = rowinfo.id
      this.roleaddedList = []
      this.multipleSelection = []
      this.UserRoleIds = []
      const res = await getUserRoles(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(item => {
        this.UserRoleIds.push(item.roleId)
      })
      this.getroleAvailList()
      this.addRoleDialogVisible = true
    },
    CheckChange (rowinfo) {
      this.multipleSelection = this.multipleSelection.concat(rowinfo)
    },
    SelectedRole () {
      this.roleaddedList = dynamicColumnId(this.multipleSelection)
      this.roleaddedList = this.roleaddedList.filter(data => { return data.checkchange === true })
    },
    removeRoleById (rowinfo) {
      this.roleaddedList = this.roleaddedList.filter((data) => { return data.id !== rowinfo.id })
      this.multipleSelection = this.roleaddedList
    },
    // 批量添加用户角色
    async addRoleToUser () {
      if (this.roleaddedList.length === 0) return this.$message.error('请选择需要添加的角色')

      let addroleids = []
      this.roleaddedList.forEach(item => {
        addroleids.push(item.id)
      })
      const res = await BatchaddRoles(addroleids, this.UserId)
      if (res.status !== 200) return this.$message.error('批量添加操作失败')
      this.addRoleDialogVisible = false
      this.roleaddedList = []
      this.multipleSelection = []
    },

    // remove role
    async removeroleDialog (rowinfo) {
      this.RemoveUserRole = '移除用户' + rowinfo.username + '角色'
      this.UserId = rowinfo.id
      this.UserRoleremoveList = []
      this.confirmRemovedRole = []
      this.multipleRemove = []

      const res = await getUserRoles(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      // 此处是roleid，所以需去role表中再查找
      res.data.data.forEach(async item => {
        const resl = await getRoleById(item.roleId)
        if (typeof resl.data.data !== 'undefined') {
          this.UserRoleremoveList.push(resl.data.data)
        }
      })
      this.removeRoleDialogVisible = true
    },
    handleRemovedChange (val) {
      this.multipleRemove = val
    },
    RemovedRole () {
      this.confirmRemovedRole = dynamicColumnId(this.multipleRemove)
    },
    NotremoveRoleById (rowinfo) {
      this.confirmRemovedRole = this.confirmRemovedRole.filter((data) => { return data.id !== rowinfo.id })
      this.multipleRemove = this.confirmRemovedRole
    },
    async removeRoleToUser () {
      if (this.confirmRemovedRole.length === 0) return this.$message.error('请选择需要移除的角色')
      const Result = await deleteDialog('确定移除?', this)
      if (Result === 'confirm') {
        let Removeroleids = []
        this.confirmRemovedRole.forEach(item => {
          Removeroleids.push(item.id)
        })
        const res = await BatchdeleteRoles(Removeroleids, this.UserId)
        if (res.status !== 200) return this.$message.error('批量移除操作失败')
        this.removeRoleDialogVisible = false
        this.confirmRemovedRole = []
        this.multipleRemove = []

      }

    },
    // 组织
    async viewOrganizeEditDialog (rowinfo) {
      this.OrganizeviewList = []
      const res = await getUserOrganizations(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(async item => {
        const resl = await getOrganizationById(item.organizationId)
        if (typeof resl.data.data !== 'undefined') {
          this.OrganizeviewList.push(resl.data.data)
        }
      })
      this.viewOrganizeDialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.texts {
  color: blue;
  font-size: 16px;
}
.divSpan {
  font-size: 16px;
}
</style>