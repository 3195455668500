import axios from '@/libs/api.request'

export const PrivilegefindPage = (pageNum, pageSize) => {
  let uri = '/permission/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const PrivilegefindByTag = (tag, pageNum, pageSize) => {
  let uri = '/permission/tag/' + tag + '/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const deletePrivilege = id => {
  let uri = '/permission/' + id
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const getPrivilegeById = id => {
  let uri = '/permission/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const updatePrivilege = (entity, id) => {
  let uri = '/permission/' + id
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

export const addPrivilege = entity => {
  let uri = '/permission/'
  return axios.request({
    url: uri,
    method: 'put',
    data: entity
  })
}

export const CountPrivilegeTag = tag => {
  let uri = '/permission/count/tag/' + tag
  return axios.request({
    url: uri,
    method: 'get'
  })
}
